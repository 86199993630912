import _taggedTemplateLiteral from "/codebuild/output/src583954317/src/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2;
import { API, graphqlOperation } from 'aws-amplify';
import gql from 'graphql-tag';
import handleApiResponse from './handleApiResponse';
const getCurrentProviderCityApiRequest = () => {
  const query = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query getCurrentProviderCity {\n      getCurrentProviderCity {\n        id\n        name\n        latitude\n        longitude\n      }\n    }\n  "])));
  return handleApiResponse(API.graphql(graphqlOperation(query)));
};
const getProvidersForLandingApiRequest = async (onlyActive, categoryId, cityId, providerType, keyword, onlyMyMemberships) => {
  const query = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    query getProviders($categoryId: Int, $cityId: Int, $providerType: String, $keyword: String, $onlyMyMemberships: Boolean, $onlyActive: Boolean) {\n      getProviders(\n        categoryId: $categoryId\n        cityId: $cityId\n        providerType: $providerType\n        keyword: $keyword\n        onlyMyMemberships: $onlyMyMemberships\n        onlyActive: $onlyActive\n      ) {\n        id\n        name\n        type\n        description\n        address\n        images {\n          medium\n        }\n        categories {\n          id\n          name\n        }\n      }\n    }\n  "])));
  return handleApiResponse(API.graphql(graphqlOperation(query), {
    onlyActive,
    categoryId,
    cityId,
    providerType,
    keyword,
    onlyMyMemberships
  }));
};
export { getCurrentProviderCityApiRequest, getProvidersForLandingApiRequest };